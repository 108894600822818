<template>
  <div class="dashboard-container">
    <Operate
      v-if="dialog.refresh"
      :dialog.sync="dialog.show"
      :row="dialog.row"
      :info="dialog.info"
      @get-list="getList"
      @refresh="refreshDialog"
    />
    <el-row :gutter="20">
      <el-col>
        <!--        <el-card>-->
        <!--          <el-row type="flex" justify="space-between" :gutter="50">-->
        <!--            <el-col>-->
        <!--              <el-input v-model="condition.search" class="input searchInput" size="mini" suffix-icon="el-icon-search" placeholder="搜索" @input="searchInput" />-->
        <!--            </el-col>-->
        <!--          </el-row>-->
        <!--        </el-card>-->
        <el-card style="margin-top: 20px;">
          <el-row>
            <vxe-toolbar>
              <template v-slot:buttons>
                <el-row type="flex" justify="between">
                  <el-col>
                    <el-button type="warning" size="mini" @click="$refs.table.setAllTreeExpand(true)">展开所有</el-button>
                    <el-button type="info" size="mini" @click="$refs.table.clearTreeExpand()">收起所有</el-button>
                  </el-col>
                  <el-col :span="1">
                    <vxe-button @click="getList">刷新</vxe-button>
                  </el-col>
                </el-row>
              </template>
            </vxe-toolbar>
            <vxe-table
              ref="table"
              border
              resizable
              show-overflow
              :auto-resize="true"
              :header-row-style="headerStyle"
              :row-style="rowStyle"
              align="center"
              class="vxe-table-element"
              height="600"
              row-id="id"
              :tree-config="{lazy: true, hasChild: 'hasChild', loadMethod: loadChildrenMethod,children: 'children', expandAll: false,indent:15}"
              :data="table.list"
              :loading="loading"
            >
<!--              <vxe-table-column type="checkbox" width="60" fixed="left" />-->
              <vxe-table-column type="seq" width="60" />
              <vxe-table-column
                field="areaName"
                title="地区名称"
                align="left"
                tree-node
              />
            </vxe-table>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { rowStyle, headerStyle } from '@/utils/tableStyleJs'
import { getAreaTree, getAreaChildren } from '@/api'
import { transData } from '@/utils/handleJson'
import Operate from './Operate'
export default {
  name: 'Index',
  components: {
    Operate
  },
  data() {
    return {
      loading: false,
      condition: {
        search: ''
      },
      table: {
        list: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
        sortName: '',
        sortBy: ''
      },
      dialog: {
        row: Object,
        info: {},
        refresh: false, // 每次刷新DOM
        show: false
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    rowStyle,
    headerStyle,
    // 打开dialog
    openDialog(info, row) {
      this.dialog.refresh = true
      this.dialog.show = true
      this.dialog.info = info
      this.dialog.row = row
    },
    searchInput(v) {
      this.getList()
    },
    getList() {
      this.loading = true
      this.$axiosReq(getAreaChildren, null, null, 'get').then(res => {
        for (const i of res.data) {
          this.table.list.push({
            ...i,
            hasChild: i.level < 3
          })
        }
        // this.table.list=res.data
        this.loading = false
      }).catch(e => {
        this.loading = false
      })
    },
    loadChildrenMethod({ row }) {
      // 异步加载子节点
      return new Promise(resolve => {
        this.$axiosReq(getAreaChildren, null, {
          parentId: row.id
        }, 'get').then(res => {
          const arr = []
          for (const i of res.data) {
            arr.push({
              ...i,
              hasChild: i.level < 3
            })
          }
          resolve(arr)
        })
      })
    },
    handleSizeChange(pageSize) {
      this.table.pageSize = pageSize
      this.table.currentPage = 1
      this.getList()
    },
    handleCurrentChange(currentPage) {
      this.table.currentPage = currentPage
      this.getList()
    },
    refreshDialog() {
      this.dialog.refresh = false
    }
  }
}
</script>

<style scoped>

</style>
